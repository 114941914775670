import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import LogoBlue from '../assets/images/logo-blue.png'
import LoginBG from '../assets/images/login.png'

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { emailPattern } from '../common/utilities';

import infiniteLoader from '../assets/images/infinite_loader.gif'
import { userSignup } from './actionMethods/actionMethods';
import { userLogin } from 'src/login/actionMethods/actionMethods';


function Signup (props) {

  const [name,SetName] = useState('');
  const [emailID, updateEmailID] = useState('')
  const [password, updatePassword] = useState('')
  const [cnfmpassword, updateCnfmPassword] = useState('');
  const [designaion,SetDesignation]= useState('');
  const [companyName,SetCompanyName]= useState('');
  const [address,SetAddress] = useState('');
  const [description,SetDescription] = useState('');
  const [latitude,SetLatitude] = useState('');
  const [longitude,SetLongitude] = useState('');
  const [nameError,SetNameError] = useState('');
  const [emailIDError, updateEmailIDError] = useState('')
  const [passwordError, updatePasswordError] = useState('')
  const [cnfmpasswordError, updateCnfmPasswordError] = useState('');
  const [companyNameError,SetCompanyNameError]= useState('');
  const [addressError,SetAddressError] = useState('');
  const [latitudeError,SetLatitudeError] = useState('');
  const [longitudeError,SetLongitudeError] = useState('');


  const [isEmailValid, updateIsEmailValid] = useState(true)
  const [isPasswordEmpty, updateIsPasswordEmpty] = useState(false)

  const [somethingWentWrongFlag, updateSomethingWrongWentFlag] = useState(false)
  const [errorMessage, updateErrorMessage] = useState('')
  const [successMessage, updateSucessMessage] = useState('')
  const [isLoading, updateIsLoading] = useState(false)
  let emailTest = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g


 async function handleSubmit(event){
      event.preventDefault();
      let check=true;
      if (emailID =='') {updateEmailIDError("EmailID is Required");check=false}else if(!emailTest.test(emailID)){updateEmailIDError("EmailID is Invalid");check= false;}else {updateEmailIDError("");}
      if(name==""){SetNameError('Name is Required');check=false}else{SetNameError('');}
      if(password==""){updatePasswordError("Password is Required");check=false}else{updatePasswordError("");}
      if(cnfmpassword==""){updateCnfmPasswordError("Confrim Password is Required");check=false}else if(password!=cnfmpassword){updateCnfmPasswordError("Password and Confrim Password is not match");}else{updateCnfmPasswordError("");}
      if(address==""){SetAddressError('Address is Required');check=false}else{SetAddressError('');}
      if(companyName==""){SetCompanyName('Company name is Required');check=false}else{SetCompanyNameError('');}
      if(latitude==""){SetLatitudeError('Latitude is Required');check=false}else{SetLatitudeError('');}
      if(longitude==""){SetLongitudeError('Longitude is Required');check=false}else{SetLongitudeError('');}

      if (password == '') {
        updateIsPasswordEmpty(true)
      }

      

        let isValid = emailPattern.test(emailID)

        if (check) {
          updateIsLoading(true)
          let requestBody = 
          {
              "org_name" : companyName,
              "organisation_description" : designaion,
              "logo_url" : "",
              "email" : emailID,
              "password" : password,
              "username" : emailID,
              "designation" : designaion,
              "lat" : latitude,
              "lon" : longitude,
              "address" : address
          }
          

          userSignup(requestBody).then(async(res) => {
            console.log("res",res);
            if(res.data.status===200){

            
           let body ={
            username:emailID,
            password:password
           } 
                    

          let userLogined = await userLogin(body).then(res=>res.data) ; 
            updateIsLoading(false)
            if (res && res.status >= 200 && res.status <= 299) {
              //// // //console.log("Status", res.data.status)

              if (res.data && res.data.status == 200) {
            
                console.log("res.data.data",userLogined.data)
                localStorage.setItem('userLoginDetailsSafeAir', JSON.stringify(userLogined.data))
                localStorage.setItem('isLoggedInSafeAir', true)
                props.history.push('/dashboard')
              } else {
                updateSomethingWrongWentFlag(true)
                updateErrorMessage(userLogin.message)

                setTimeout(() => {
                    updateSomethingWrongWentFlag(false)
                  
                }, 3000);
              }
            }
          }else{
            console.log("message",res.data.message)
            updateErrorMessage(res.data.message);
            updateIsLoading(false);
            updateSomethingWrongWentFlag(true)  
          }
            //// // //console.log("response",res);
            // localStorage.setItem('isLoggedInSafeAir', true)        
            // props.history.push('/dashboard')      
          })
          .catch(err => {
            updateSomethingWrongWentFlag(true)
            updateIsLoading(false);
            updateErrorMessage('Username and password do not match')
          })


        }
        else {
          updateIsEmailValid(false)
        }
      
  } 
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.permissions.query({ name: 'geolocation' }).then(function(permissionStatus) {
        if (permissionStatus.state === 'granted') {
          // Permission already granted
          navigator.geolocation.getCurrentPosition(getLocation);
          
        } else if (permissionStatus.state === 'prompt') {
          // Permission not yet granted, ask for permission
          navigator.geolocation.getCurrentPosition(getLocation);
        } else {
          // Permission denied or unavailable
          console.warn("Geolocation permission denied or unavailable");
        }
      });
    } else {
      console.warn("Geolocation not supported");
    }
  }, []);

  function getLocation(position) {
    
      var latitude = position.coords.latitude;
      var longitude = position.coords.longitude;

      SetLatitude(latitude);
      SetLongitude(longitude);
      // Fetch address using reverse geocoding
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCNciuc_myP2xM41RyNQ6juIdMyJpjkILU`)
        .then(response => response.json())
        .then(data => {
          if (data.status === "OK") {
            var addressVar = data.results[0].formatted_address;
            SetAddress(addressVar)
            console.log("Address: " + addressVar);
          } else {
            console.error("Reverse geocoding failed:", data.status);
          }
        })
        .catch(error => {
          console.error("Error fetching address:", error);
        });
  }


  return (
    <div className="login-form-extend bg-white min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center align-items-center">
          <CCol md={6}>
            <div className="signin-div">
              <img src={LogoBlue} className="logo-sgnin"/>
            </div>
            <div className="sign-in graphic">
              <img src={LoginBG}/>
            </div>
          </CCol>
          <CCol md={6} className="signin-form">
            <CCardGroup>
              <CCard className="p-4">

                <CCardBody>
                  <form onSubmit={handleSubmit}>
                    <h1 className="mb-6">Signup</h1>
                    <CRow className="justify-content-center align-items-center">
                      <CCol>
                        <label>Name</label>
                    <CInputGroup className="mb-1">                    
                      <CFormInput placeholder="Name" autoComplete="username" value={name} onChange={(e) => SetName(e.target.value)} maxLength={150}/>  
                    </CInputGroup>
                    {nameError ? <div className="dangerColor">{nameError}</div> : ''}
                      </CCol>
                      <CCol>
                                           
                    <label>Email</label>
                    <CInputGroup className="mb-1">                    
                      <CFormInput placeholder="Email" autoComplete="username" value={emailID} onChange={(e) => updateEmailID(e.target.value)} maxLength={150}/>  
                    </CInputGroup>
                    {emailIDError ? <div className="dangerColor">{emailIDError}</div> : ''}
                      </CCol>
                    </CRow>
                     <CRow className="justify-content-center align-items-center">
                    <CCol>
                      <label className="login_spacing_component">Password</label>
                    <CInputGroup className="mb-1">
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        value={password}          
                        onChange={(e) => updatePassword(e.target.value)} 
                        maxLength="150"
                      />
                    </CInputGroup>
                    {
                      passwordError ? <div className="dangerColor">{passwordError}</div> : ''
                    }
                    </CCol>  
                    <CCol>
                      <label className="login_spacing_component">Confirm Password</label>
                      <CInputGroup className="mb-1">
                        <CFormInput type="password" placeholder="Confirm Password" autoComplete="current-password" value={cnfmpassword} onChange={(e) => updateCnfmPassword(e.target.value)}  maxLength="150"
                        />
                      </CInputGroup>
                      {
                        cnfmpasswordError? <div className="dangerColor">{cnfmpasswordError}</div> : ''
                      }
                    </CCol>'/'
                      </CRow> 

                     <CRow className="justify-content-center align-items-center">
                    <CCol>
                    <label>Company</label>
                    <CInputGroup className="mb-1">                    
                      <CFormInput placeholder="Compnay" autoComplete="company" value={companyName} onChange={(e) => SetCompanyName(e.target.value)} maxLength={150}/>  
                    </CInputGroup>
                    {
                        companyNameError? <div className="dangerColor">{companyNameError}</div> : ''
                    }


                    </CCol>  
                    <CCol>
                    <label>Designation</label>
                    <CInputGroup className="mb-1">                    
                      <CFormInput placeholder="Designation" autoComplete="designation" value={designaion} onChange={(e) => SetDesignation(e.target.value)} maxLength={150}/>  
                    </CInputGroup>
                    </CCol>
                      </CRow> 

                     <CRow className="justify-content-center align-items-center">

                    <CCol>
                    <label>Address</label>
                    <CInputGroup className="mb-1">                    
                      <CFormInput placeholder="Address" autoComplete="address" value={address} onChange={(e) => SetAddress(e.target.value)} maxLength={150}/>  
                    </CInputGroup>
                    </CCol>
                      </CRow> 
                    {
                        addressError? <div className="dangerColor">{addressError}</div> : ''
                    }


                    <CRow>
                    <CCol>
                    <label>Latitude</label>
                    <CInputGroup className="mb-1">                    
                      <CFormInput placeholder="Latitude" autoComplete="Latitude" value={latitude} onChange={(e) => SetLatitude(e.target.value)} maxLength={150}/>  
                    </CInputGroup>
                     {
                        latitudeError? <div className="dangerColor">{latitudeError}</div> : ''
                    }
                    </CCol>
                     
                    <CCol>
                    <label>Longitude</label>
                    <CInputGroup className="mb-1">                    
                      <CFormInput placeholder="Address" autoComplete="address" value={longitude} onChange={(e) => SetLongitude(e.target.value)} maxLength={150}/>  
                    </CInputGroup>
                     {
                        longitudeError? <div className="dangerColor">{longitudeError}</div> : ''
                    }
                    </CCol>
                      </CRow> 


                    <CRow>
                    <CCol md={12} className="login_spacing_component" style={{textAlign:"center"}} >
                      {
                        isLoading ?
                          <img src={infiniteLoader} /> :<>
                          <input type="submit" className="button button-primary  btn-bigger" value="Login"/>
                          <label className="login_spacing_component" style={{cursor:"pointer"}} onClick={()=>props.history.push('/login')}><span style={{color:"#000"}}>Already have an Account? </span>Login</label>
                          </>
                          
                      }                      
                      </CCol>
                    </CRow>


                    {
                      somethingWentWrongFlag ?
                          <div className="dangerColor text-center">{errorMessage}</div> : ''
                    }

                  </form>
                                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )

  function handleEmailID(value) {
    updateEmailID(value)
  }
  
  function handlePassword(value) {
    updatePassword(value)
    updateIsPasswordEmpty(false)
  }
  
}
export default Signup
